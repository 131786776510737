import React from 'react'
import { Link } from 'gatsby';

export default function featuredArticle(props) {
  const { article } = props
  const { title } = article.node.frontmatter
  const author = article.node.frontmatter.author
  const { excerpt } = article.node
  const { slug, date } = article.node.fields
  const thumbnail = article.node.frontmatter.thumbnail || props.site.defaultPostThumbnail

  return (
    <div className='hero hero-2 blog-hero'>
      <div className='hero-2__background'></div>
      <div className='hero-2__container'>
        <div className='title-label-1'>
          <div className='title-label-1__count'>
            05
          </div>
          <div className='title-label-1__seperator'></div>
          <div className='title-label-1__label'>
            Blog
          </div>
        </div>
        <h1 className='hero-1__title'>
          In The Works
        </h1>
        <div className='blog-hero__wrapper'>
          <div className='blog-hero__pattern'>
            <img src='/images/case-hero-pattern.png' alt='' />
          </div>
          <div className='content-block-1__title-label'>
            <h2 className='h2-label'>
              <div className='h2-label__title'>
                Featured
              </div>
              <div className='h2-label__seperator'></div>
            </h2>
          </div>
          <div className='blog-hero__feature-block'>
            <div className='feature-block__background'>
              <img src={thumbnail} alt='' />
            </div>
            <div className='feature-block__article-info'>
              <h3 className='article-info__title'>
                <Link to={slug}>
                  {title}
                </Link>
              </h3>
              <div className='article-info__article-meta'>
                <div className='article-meta__avatar'>
                  <img src={author.image} alt='' />
                </div>
                <div className='article-meta__author'>
                  {author.name}
                </div>
                <div className='article-meta__sep'></div>
                <div className='article-meta__date'>
                  {date}
                </div>
              </div>
              <div className='article-info__description'>
                <p>{excerpt}</p>
              </div>
              <div className='article-info__cta'>
                <Link className='read-more' to={slug}>
                  read more
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
