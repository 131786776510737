import React from 'react'
import { Link } from 'gatsby';

export default function articleGridBg(props) {
  const defaultImage = props.site.defaultPostThumbnail
  return (
    <div className='blog-listings'>
      <div className='blog-listings__blog-grid-1'>
        <ul className='blog-grid-1__list'>
          {
              props.articles.map((article, index) => {
              const { title } = article.node.frontmatter
              const author = article.node.frontmatter.author
              const { id, excerpt } = article.node
              const { slug, date } = article.node.fields
              const thumbnail = article.node.frontmatter.thumbnail || defaultImage

              return (
                <li
                  key={id}
                  className={`blog-grid-1__list-item ${index % 2 !== 0 ? 'blog-grid-1__list-item--large' : ''}`}
                >
                  <div className='article-info__wrapper'>
                    <h3 className='article-info__title'>
                      <Link to={slug}>
                        {title}
                      </Link>
                    </h3>
                    <div className='article-info__article-meta'>
                      <div className='article-meta__avatar'>
                        <img src={author.image} alt='' />
                      </div>
                      <div className='article-meta__author'>
                        {author.name}
                      </div>
                      <div className='article-meta__sep'></div>
                      <div className='article-meta__date'>
                        {date}
                      </div>
                    </div>
                    <div className='article-info__description'>
                      <p>
                        {excerpt}
                      </p>
                    </div>
                    <div className='article-info__cta'>
                      <Link className='read-more' to={slug}>
                        Read More
                      </Link>
                    </div>
                  </div>
                  <div className='article-info__image'>
                    <div className='article-info__bg-gradient'></div>
                    <img src={thumbnail} alt='' />
                  </div>
                </li>
              )
            })
          }
        </ul>
      </div>
    </div>
  )
}
