import React from 'react'
import Layout from '@components/layout';
import ArticleGrid from '@components/article-grid'
import ArticleGridBg from '@components/article-grid-bg'
import FeaturedArticle from '@components/featured-article'
import { Link, graphql } from 'gatsby';

export default function blog(props) {
  const articles = props.data.allMarkdownRemark.edges
  const featuredArticle = articles[0]
  const middleArticles = articles.slice(1, 5)
  const gridArticles = articles.slice(5, articles.length)
  const siteData = props.data.site.siteMetadata

  return (
    <Layout location={props.location} title='Blog' namespace='blog'>
      <FeaturedArticle article={featuredArticle} site={siteData} />

      <section>
        <ArticleGridBg articles={middleArticles} site={siteData} />
      </section>

      <section className='recent-articles'>
        <div className='content-block-2'>
          <div className='content-block-2__title-label'>
            <h2 className='h2-label'>
              <div className='h2-label__title'>
                Recent Articles
              </div>
              <div className='h2-label__seperator'></div>
            </h2>
          </div>
          <div className='content-block-2__content'>
            <h3>
              Catch up on all the latest and greatest
            </h3>
          </div>
        </div>

        <ArticleGrid articles={gridArticles}/>

        <div className='blog-listings__more-articles'>
          <Link className='primary-button' to='/archive'>
            All the articles
            <div className='primary-button__icon'>
              <div className='icon-right-arrow-icon'></div>
            </div>
          </Link>
        </div>
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  {
    allMarkdownRemark(
      sort: {fields: [fields___date], order: DESC}
      limit: 11
    ) {
      edges {
        node {
          id
          excerpt
          fields {
            slug
            date(formatString: "MMM DD, YYYY")
          }
          frontmatter {
            title
            author {
              name
              image
            }
            thumbnail
          }
        }
      }
    }
    site {
      siteMetadata {
        defaultPostThumbnail
      }
    }
  }
`
