import React from 'react'
import { Link } from 'gatsby';

export default function articleGrid(props) {
  const { articles } = props

  return (
    <div className='blog-listings__blog-grid-2'>
      <ul className='blog-grid-2__list'>
        {
          articles.map(article => {
            const { title } = article.node.frontmatter
            const author = article.node.frontmatter.author
            const { id, excerpt } = article.node
            const { slug, date } = article.node.fields

            return (
              <li className='blog-grid-2__list-item' key={id}>
                <h3 className='article-info__title'>
                  <Link to={slug}>
                    {title}
                  </Link>
                </h3>
                <div className='article-info__article-meta'>
                  <div className='article-meta__avatar'>
                    <img src={author.image} alt='' />
                  </div>
                  <div className='article-meta__author'>
                    {author.name}
                  </div>
                  <div className='article-meta__sep'></div>
                  <div className='article-meta__date'>
                    {date}
                  </div>
                </div>
                <div className='article-info__description'>
                  <p>
                    {excerpt}
                  </p>
                </div>
                <div className='article-info__cta'>
                  <Link className='read-more' to={slug}>
                    Read More
                  </Link>
                </div>
              </li>
            )
          })
        }
      </ul>
    </div>
  )
}
